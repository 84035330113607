import 'bootstrap';
import 'slick-carousel';

// On Dom Ready
$(function () {
	// Top Employers Carousel
	$('.top-employers .the-carousel').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 700,
		slidesToShow: 3,
		slidesToScroll: 3,
		adaptiveHeight: true,
		rows: 0,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 579,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	// Employer of the week  Carousel
	$('.employer-of-the-week .the-carousel').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 700,
		slidesToShow: 4,
		slidesToScroll: 4,
		adaptiveHeight: true,
		rows: 0,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 579,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	// Jobs by location Carousel
	$('.jobs-by-location .the-carousel').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 700,
		slidesToShow: 2,
		slidesToScroll: 1,
		adaptiveHeight: true,
		rows: 0,
		vertical: true,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 579,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	// Top Recruiters Carousel
	$('.top-recruiters .the-carousel').slick({
		autoplay: true,
		autoplaySpeed: 3000,
		dots: false,
		arrows: false,
		infinite: true,
		speed: 700,
		slidesToShow: 4,
		slidesToScroll: 4,
		adaptiveHeight: true,
		rows: 0,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 579,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	});

	// Toggle mobile menu
	$('.menu-open').on('click', function () {
		$('.mobile-menu-wrapper').addClass('active');
		$(this).addClass('is-active');
		$('body').addClass('noscroll');
	});
	$('.menu-close').on('click', function () {
		$('.mobile-menu-wrapper').removeClass('active');
		$('.menu-open').removeClass('is-active');
		$('body').removeClass('noscroll');
	});
});

// After page loaded completely
$(window).on('load', function () {
	$('.mobile-menu-wrapper').addClass('initialized');
});

$(window).on('resize', function () {
	$('.menu-close').trigger('click');
});
